/* eslint-disable no-inner-declarations */

import twemoji from "twemoji";
import Glide from "@glidejs/glide";
// eslint-disable-next-line import/no-named-as-default
import Splide from "@splidejs/splide";
import throttle from "lodash/throttle";
import escape from "lodash/escape";
import tippy, { roundArrow } from "tippy.js";
import Cookies from "js-cookie";

import modal from "./js/modal";
import Variator from "./js/variant-testing";
import topBannerController from "./js/top-banner-controller";
import capterraTracking from "./js/capterra";
import initActionItems from "./blocks/action-item-client";

import "./css/_webfonts.scss";
import "./style.scss";
import { growthbook } from "./js/growthbook";

const Tests = new Variator();

window.analytics.ready(() => {
  Tests.trackExperiments();
});

function getTomorrow() {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  return tomorrow;
}

function formatDate(date: Date) {
  const day = date.toLocaleString("en-US", { weekday: "short" });
  const month = date.toLocaleString("en-US", { month: "short" });
  const dayOfMonth = date.getDate();
  return `${day}, ${month} ${dayOfMonth}`;
}

function todayDate() {
  const date = new Date();
  return date.getDate();
}

function todayWeekday() {
  const date = new Date();
  return date.toLocaleString("en-US", { weekday: "long" });
}

function tomorrowDate() {
  const tomorrow = getTomorrow();
  return tomorrow.getDate();
}

function tomorrowWeekday() {
  const tomorrow = getTomorrow();
  return tomorrow.toLocaleString("en-US", { weekday: "long" });
}

function todayFormated() {
  const date = new Date();
  return formatDate(date);
}

function replaceDates() {
  // Replace text content of tags with these data-* attributes
  // data-today-formated, date-tomorrow-formated, data-today-date, data-tomorrow-date, data-today-weekday, data-tomorrow-weekday
  // with actual values
  document.querySelectorAll("[data-today-formated]").forEach(el => {
    el.textContent = todayFormated();
  });
  document.querySelectorAll("[data-tomorrow-formated]").forEach(el => {
    el.textContent = formatDate(getTomorrow());
  });
  document.querySelectorAll("[data-today-date]").forEach(el => {
    el.textContent = todayDate().toString();
  });
  document.querySelectorAll("[data-tomorrow-date]").forEach(el => {
    el.textContent = tomorrowDate().toString();
  });
  document.querySelectorAll("[data-today-weekday]").forEach(el => {
    el.textContent = todayWeekday();
  });
  document.querySelectorAll("[data-tomorrow-weekday]").forEach(el => {
    el.textContent = tomorrowWeekday();
  });
}

document.addEventListener("DOMContentLoaded", () => {
  const topBanner = document.querySelector<HTMLElement>(".top-banner");

  initActionItems();

  replaceDates();

  // For the reason we don't just use position: sticky here, see _header.scss
  const headerSpacer = document.querySelector<HTMLDivElement>(".header-spacer");
  const siteHeader = document.querySelector<HTMLDivElement>(".site-header");
  if (headerSpacer && siteHeader) {
    function updateHeaderSpacer() {
      if (!siteHeader || !headerSpacer) return;
      if (siteHeader.classList.contains("site-header--expanded")) {
        return;
      }
      document.documentElement.style.setProperty("--nav-height", `${siteHeader.offsetHeight}px`);
    }

    const throttledUpdateHeaderSpacer = throttle(updateHeaderSpacer, 100);

    window.addEventListener("resize", throttledUpdateHeaderSpacer);
    window.addEventListener("load", throttledUpdateHeaderSpacer);
    [...siteHeader.querySelectorAll("img")].forEach(el => el.addEventListener("load", throttledUpdateHeaderSpacer));
    updateHeaderSpacer();
  }

  if (siteHeader) {
    function updateHeaderStyle() {
      siteHeader?.classList.toggle("site-header--scrolled", window.scrollY > siteHeader.scrollTop);
    }
    const throttledUpdateHeaderStyle = throttle(updateHeaderStyle, 100);
    window.addEventListener("scroll", throttledUpdateHeaderStyle);

    /* *************** *
     *   MOBILE NAV    *
     * *************** */

    const hamburger = document.querySelector(".hamburger");
    if (hamburger) {
      hamburger.addEventListener("click", () => {
        siteHeader.classList.toggle("site-header--expanded");
        document.querySelector(".hamburger")?.classList.toggle("is-active");
        document.querySelector("body")?.classList.toggle("mobile-menu-expanded");
      });
    }

    const menuDropdowns = siteHeader.querySelectorAll<HTMLAnchorElement>(".menu-item-has-children > a");
    if (menuDropdowns) {
      const clickOutsideNavDropdowns = ({ target }: MouseEvent) => {
        if (!(target instanceof HTMLElement)) return;
        const currentOpenDropdown = document.querySelector(".menu-item-has-children.open");
        if (!target.closest(".menu-item-has-children") && currentOpenDropdown) {
          currentOpenDropdown.classList.remove("open");
          siteHeader.classList.remove("site-header--submenu-open");
        }
      };
      window.addEventListener("click", clickOutsideNavDropdowns);

      menuDropdowns.forEach(el => {
        el.addEventListener("click", e => {
          e.preventDefault();

          const { target } = e;
          if (!(target instanceof HTMLElement)) return;
          const menuItem = target.parentElement;

          Array.from(siteHeader.querySelectorAll(".menu-item-has-children"))
            .filter(el => el != menuItem)
            .map(el => el.classList.remove("open"));

          const isOpen = menuItem?.classList.toggle("open");
          siteHeader.classList.toggle("site-header--submenu-open", isOpen);
        });

        // Prevent double-clicks from selecting the button text
        el.addEventListener("mousedown", e => {
          if (e.detail > 1) e.preventDefault();
        });
      });
    }
  }

  /* *************** *
   *  EMOJI SUPPORT  *
   * *************** */

  twemoji.parse(document.body, {
    base: "https://cdnjs.cloudflare.com/ajax/libs/twemoji/11.2.0/2/",
    folder: "svg",
    ext: ".svg",
  });

  /* *************** *
   *  TESTIMONIALS   *
   * *************** */

  if (document.querySelector(".glide.testimonials:not(.no-auto-switch)")) {
    new Glide(".glide.testimonials:not(.no-auto-switch)", {
      type: "carousel",
      touchAngle: 30,
      autoplay: 10000,
    }).mount();
  }

  /* ****************** *
   *  NEW TESTIMONIALS  *
   * ****************** */
  const testimonialSections = document.querySelectorAll<HTMLElement>(".testimonials-block--multiple");
  if (testimonialSections) {
    testimonialSections.forEach(testimonialSection => {
      let currentActiveIndex = 0;
      const carousel = new Glide(testimonialSection, {
        type: "carousel",
        perView: 1,
        touchAngle: 30,
        autoplay: 10000,
        hoverpause: false,
        animationDuration: 800,
        animationTimingFunc: "ease",
        gap: 0,
      });
      const mobileProgressBar = testimonialSection.querySelector<HTMLDivElement>(
        ".testimonials-block__progress-bar--mobile .testimonials-block__progress-bar--filled",
      );
      const restartProgressAnimation = (progressBar: HTMLDivElement) => {
        if (!progressBar) return;
        progressBar.classList.remove("progress-animation");
        //  Trigger a DOM reflow. We should be good performance wise since this does not happens very often
        void progressBar.offsetWidth;
        progressBar.classList.add("progress-animation");
      };
      if (mobileProgressBar) {
        carousel.on("run", () => restartProgressAnimation);
        restartProgressAnimation(mobileProgressBar);
      }
      const testimonialMenuItems = testimonialSection.querySelectorAll(".testimonials-block__menu-item");
      if (!testimonialMenuItems) {
        return;
      }
      const mountedCarousel = carousel.mount();
      testimonialMenuItems.forEach((testimonialMenuItem, index) => {
        testimonialMenuItem.addEventListener("click", () => mountedCarousel.go(`=${index}`));
      });
      const changeActiveIndex = (newActiveIndex: number) => {
        testimonialMenuItems[currentActiveIndex].classList.remove("testimonials-block__menu-item--active");
        testimonialMenuItems[newActiveIndex].classList.add("testimonials-block__menu-item--active");
        const oldProgressBar = testimonialMenuItems[currentActiveIndex].querySelector<HTMLDivElement>(
          ".testimonials-block__progress-bar--filled",
        );
        if (oldProgressBar) {
          if (currentActiveIndex === newActiveIndex) {
            restartProgressAnimation(oldProgressBar);
            return;
          }
          oldProgressBar.classList.remove("progress-animation");
        }
        const newProgressBar = testimonialMenuItems[newActiveIndex].querySelector(
          ".testimonials-block__progress-bar--filled",
        );
        if (newProgressBar) {
          newProgressBar.classList.add("progress-animation");
        }
        currentActiveIndex = newActiveIndex;
      };
      carousel.on("run", () => changeActiveIndex(mountedCarousel.index));
      changeActiveIndex(currentActiveIndex);
    });
  }

  /* ********************** *
   *  SCREENSHOT SWITCHER   *
   * ********************** */

  if (document.querySelector(".screenshot-switcher__desktop")) {
    document.querySelectorAll(".screenshot-switcher__desktop .screenshot-switcher__item").forEach(el => {
      el.addEventListener("click", e => {
        e.preventDefault();

        const { currentTarget } = e;
        if (!(currentTarget instanceof HTMLAnchorElement)) return;
        const id = currentTarget.hash.substr(1);
        const slide = document.getElementById(id);

        document.querySelectorAll(".screenshot-switcher__item").forEach(item => {
          item.classList.toggle("screenshot-switcher__item--current", item === e.currentTarget);
        });

        document.querySelectorAll(".screenshot-switcher__slide").forEach(item => {
          item.classList.toggle("screenshot-switcher__slide--current", item === slide);
        });
      });
    });
  }
  if (document.querySelector(".screenshot-switcher__mobile")) {
    new Glide(".screenshot-switcher__mobile.glide", {
      type: "slider",
      perView: 1,
      touchAngle: 30,
      autoplay: 10000,
    }).mount();
  }

  /* ******************* *
   *  FEATURES SWITCHER  *
   * ******************* */
  const featureSections = document.querySelectorAll(".features-block");
  if (featureSections) {
    const timeInMsToNextItem = 10000;
    featureSections.forEach(featureSection => {
      const menuItemsWrapper = featureSection.querySelector<HTMLDivElement>(".features-block__menu");
      const imagesWrapper = featureSection.querySelector<HTMLDivElement>(".features-block__images");
      const tabItemsWrapper = featureSection.querySelector<HTMLDivElement>(".features-block__tab-content");
      if (menuItemsWrapper && imagesWrapper && tabItemsWrapper) {
        imagesWrapper.style.height = `${menuItemsWrapper.offsetHeight}px`;
      }
      const switcherMenuItems = featureSection.querySelectorAll(".features-block__menu-item");
      const switcherImages = featureSection.querySelectorAll(".features-block__images .features-block__image");
      const switcherHeaders = featureSection.querySelectorAll(".features-block__images .features-block__header");
      const switcherDescriptions = featureSection.querySelectorAll(
        ".features-block__images .features-block__description",
      );
      const switcherMenuItemDescriptions = featureSection.querySelectorAll(".features-block__menu-item--description");
      if (
        (switcherMenuItems.length > 0 && switcherMenuItems.length === switcherImages.length) ||
        (switcherHeaders && switcherDescriptions)
      ) {
        let currentActiveIndex = 0;
        let currentProgressTimer: number;

        const nextItem = () => {
          currentActiveIndex++;
          if (currentActiveIndex >= switcherMenuItems.length) {
            currentActiveIndex = 0;
          }
          toggleItem(currentActiveIndex);
        };

        const toggleItem = (index: number) => {
          if (isNaN(index)) {
            return;
          }
          const activeMenu = featureSection.querySelector(".features-block__menu-item--active");
          const activeImage = featureSection.querySelector(".features-block__image--active");
          const activeHeader = featureSection.querySelector(".features-block__header--active");
          const activeDescription = featureSection.querySelector(".features-block__description--active");
          const activeItemDescription = featureSection.querySelector(".features-block__menu-item--description--active");

          if ((activeMenu && activeImage) || (activeHeader && activeDescription)) {
            activeMenu?.classList.remove("features-block__menu-item--active");
            activeImage?.classList.remove("features-block__image--active");
            activeHeader?.classList.remove("features-block__header--active");
            activeDescription?.classList.remove("features-block__description--active");
            activeItemDescription?.classList.remove("features-block__menu-item--description--active");
            const currentProgressBar = activeMenu?.querySelector(".features-block__progress-bar--filled");
            if (currentProgressBar) {
              currentProgressBar.classList.remove("progress-animation");
            }
          }

          switcherMenuItems[index]?.classList.add("features-block__menu-item--active");
          switcherImages[index]?.classList.add("features-block__image--active");
          switcherHeaders[index]?.classList.add("features-block__header--active");
          switcherDescriptions[index]?.classList.add("features-block__description--active");
          switcherMenuItemDescriptions[index]?.classList.add("features-block__menu-item--description--active");

          const newProgressBar = switcherMenuItems[index]?.querySelector(".features-block__progress-bar--filled");
          if (currentProgressTimer) {
            window.clearTimeout(currentProgressTimer);
          }
          if (newProgressBar) {
            newProgressBar.classList.add("progress-animation");
            currentProgressTimer = window.setTimeout(nextItem, timeInMsToNextItem);
          }
          currentActiveIndex = index;
        };
        for (let index = 0; index < switcherMenuItems.length; index++) {
          switcherMenuItems[index].addEventListener("click", e => {
            e.preventDefault();
            toggleItem(index);
          });
        }
        toggleItem(currentActiveIndex);
      }

      /* ************************* *
       *  FEATURES SWITCHER MOBILE *
       * ************************* */
      const featureMobileSwitcher = featureSection.querySelector<HTMLDivElement>(
        ".features-block__image-switcher--mobile.glide",
      );
      if (featureMobileSwitcher) {
        const slider = new Glide(featureMobileSwitcher, {
          type: "slider",
          perView: 1,
          touchAngle: 30,
          autoplay: 10000,
          hoverpause: false,
          animationDuration: 800,
          animationTimingFunc: "ease",
        });
        slider.mount();
        const progressBar = featureMobileSwitcher.querySelector<HTMLDivElement>(
          ".features-block__progress-bar--filled",
        );
        const restartProgressAnimation = () => {
          if (!progressBar) return;
          progressBar.classList.remove("progress-animation");
          //  Trigger a DOM reflow. We should be good performance wise since this does not happens very often
          void progressBar.offsetWidth;
          progressBar.classList.add("progress-animation");
        };
        if (progressBar) {
          slider.on("run", restartProgressAnimation);
        }
        restartProgressAnimation();
      }
    });
  }

  /* ******************************* *
   *  Blog v2 related posts slider   *
   * ******************************* */

  if (document.querySelector(".glide.keep-reading-slider")) {
    new Glide(".glide.keep-reading-slider", {
      type: "carousel",
      touchAngle: 30,
      autoplay: false,
      perView: 4,
      breakpoints: {
        990: {
          perView: 3,
        },
        767: {
          perView: 2,
        },
      },
      rewind: true,
    }).mount();
  }

  if (document.querySelector(".glide.keep-reading-slider.templates")) {
    new Glide(".glide.keep-reading-slider.templates", {
      type: "carousel",
      touchAngle: 30,
      autoplay: false,
      perView: 4,
      breakpoints: {
        990: {
          perView: 3,
        },
        767: {
          perView: 2,
        },
      },
      rewind: true,
    }).mount();
  }

  /* ******************************* *
   *  Blog v2 Table of Contents  *
   * ******************************* */

  if (document.querySelector(".toc-wrap")) {
    // Function to generate a valid ID from the heading text
    function generateValidId(text: string) {
      return text
        .toLowerCase()
        .replace(/[^\w\s-]/g, "")
        .replace(/\s+/g, "-");
    }

    //adjusting the sticky positions when top banner is present
    const siteHeaderInner = document.querySelector<HTMLElement>(".site-header__inner");
    const widgetSticky = document.querySelector<HTMLElement>(".widget-sticky");
    const tocWrap = document.querySelector<HTMLElement>(".toc-wrap");

    if (siteHeaderInner && topBanner && topBanner.innerHTML.trim() !== "" && topBanner.offsetHeight > 0) {
      const totalHeight = `${siteHeaderInner.offsetHeight + topBanner.offsetHeight + 20}px`;

      if (widgetSticky) {
        widgetSticky.style.top = totalHeight;
      }
      if (tocWrap) {
        tocWrap.style.top = totalHeight;
      }
    }

    //adjust sticky position back if top banner is closed
    const topBannerClose = document.querySelector(".top-banner__close") as HTMLElement;

    if (topBannerClose) {
      topBannerClose.addEventListener("click", () => {
        if (siteHeaderInner && topBanner && widgetSticky && tocWrap) {
          const totalHeight = `${siteHeaderInner.offsetHeight + 20}px`; // Subtract the height of topBanner

          widgetSticky.style.top = totalHeight;
          tocWrap.style.top = totalHeight;
        }
      });
    }
    let currentActive: HTMLElement | null = null;

    window.addEventListener("scroll", () => {
      const headings = document.querySelectorAll("h2.wp-block-heading");
      for (const heading of headings) {
        const rect = heading.getBoundingClientRect();
        const itemLink = document.getElementById(heading.id + "-link");
        if (rect.top >= 0 && rect.top <= 350) {
          if (currentActive && currentActive !== itemLink) {
            currentActive.classList.remove("active");
          }
          itemLink?.classList.add("active");
          currentActive = itemLink;
          break;
        }
      }
    });

    // Generate ToC
    document.querySelectorAll("h2.wp-block-heading").forEach(function (el) {
      let id = el.getAttribute("id");
      const headingText = el.textContent ? el.textContent.trim() : "";

      if (!el.getAttribute("id")) {
        id = generateValidId(headingText);
        el.setAttribute("id", id);
      }
      const anchorLink = document.createElement("a");
      anchorLink.classList.add("item-link");
      anchorLink.setAttribute("href", "#" + id);
      anchorLink.textContent = headingText.replace(/^\d+\.\d+/, "");

      const listItem = document.createElement("li");
      listItem.classList.add("toc-list-item");
      listItem.setAttribute("id", id + "-link");
      listItem.appendChild(anchorLink);

      document.querySelectorAll(".toc-list").forEach(function (tocList) {
        const clonedListItem = listItem.cloneNode(true);
        tocList.appendChild(clonedListItem);
      });
    });
  }

  /* ************************* *
   *      LOGOS AND BADGES     *
   * ************************* */
  const logosSections = document.querySelectorAll(".logos-block");
  const badgesSections = document.querySelectorAll(".badges-block");
  if (logosSections || badgesSections) {
    let incrementsPerStep: number;
    const calculateIncrementPerStep = () => {
      if (screen.width <= 767) {
        incrementsPerStep = 2;
        return;
      }
      if (screen.width <= 1279) {
        incrementsPerStep = 3;
        return;
      }
      incrementsPerStep = 4;
    };
    calculateIncrementPerStep();
    const throttledCalculateIncrementPerStep = throttle(calculateIncrementPerStep, 100);
    window.addEventListener("resize", throttledCalculateIncrementPerStep);
    // Logos
    logosSections.forEach(logosSection => {
      const logosWrapper = logosSection.querySelector("ul");
      const logos = logosSection.querySelectorAll("li");
      if (!logosWrapper || !logos || logos.length <= 0) {
        return;
      }
      //  Making sure that all logos starts deactivated;
      logos.forEach(logo => {
        logo.classList.remove("active");
      });

      let currentActiveIndex = 0;
      const timeToChangeLogosInMs = 4000;
      const nextStep = () => {
        logos[currentActiveIndex].classList.remove("active");
        currentActiveIndex += incrementsPerStep;
        if (currentActiveIndex >= logos.length) {
          currentActiveIndex = 0;
        }
        logos[currentActiveIndex].classList.add("active");
        window.setTimeout(nextStep, timeToChangeLogosInMs);
      };
      logos[0].classList.add("active");
      window.setTimeout(nextStep, timeToChangeLogosInMs);
    });
    // Badges
    badgesSections.forEach(badgesSection => {
      const badgesWrapper = badgesSection.querySelector("ul");
      const badges = badgesSection.querySelectorAll("li");
      if (!badgesWrapper || !badges || badges.length <= 0) {
        return;
      }
      //  Making sure that all badges starts deactivated;
      badges.forEach(badge => {
        badge.classList.remove("active");
      });

      let currentActiveIndex = 0;
      const timeToChangeBadgesInMs = 4000;
      const nextStep = () => {
        badges[currentActiveIndex].classList.remove("active");
        currentActiveIndex += incrementsPerStep;
        if (currentActiveIndex >= badges.length) {
          currentActiveIndex = 0;
        }
        badges[currentActiveIndex].classList.add("active");
        window.setTimeout(nextStep, timeToChangeBadgesInMs);
      };
      badges[0].classList.add("active");
      window.setTimeout(nextStep, timeToChangeBadgesInMs);
    });
  }

  /* ********************** *
   *     LOGOS v2          *
   * ********************** */
  if (document.querySelector(".logos-v2-slider") && window.innerWidth > 767) {
    new Splide(".logos-v2-slider", {
      type: "loop",
      autoWidth: true,
      perPage: 8,
      perMove: 1,
      arrows: false,
      drag: false,
      autoplay: true,
      interval: 3000,
      speed: 2000,
      gap: "3rem",
      pagination: false,
      breakpoints: {
        767: {
          perPage: 4,
        },
      },
    }).mount();
  }

  /* ********************** *
   *     TESTIMONIALS SLIDER *
   * ********************** */
  if (document.querySelector(".testimonials-slider-block__content")) {
    const elms = document.getElementsByClassName("testimonials-slider-block__content");
    for (let i = 0; i < elms.length; i++) {
      const element = elms[i] as HTMLElement;
      const splide = new Splide(element);
      splide.mount();
      // Add event listeners to custom arrows outside the slider element
      const sibling = element.previousElementSibling;
      if (sibling && sibling.classList.contains("testimonials-slider-block__header-wrap")) {
        const sliderLeft = sibling.querySelector("#slider-left");
        const sliderRight = sibling.querySelector("#slider-right");
        if (sliderLeft) {
          sliderLeft.addEventListener("click", () => {
            splide.go("+1");
          });
        }
        if (sliderRight) {
          sliderRight.addEventListener("click", () => {
            splide.go("-1");
          });
        }
      }
    }
  }

  /* *************** *
   *    TOOLTIPS     *
   * *************** */

  tippy("[data-fellow-tooltip]", {
    arrow: roundArrow,
    theme: "fellow",
    maxWidth: "15rem",
  });

  tippy("#shareResultsBtn, #copyCalculatorUrl", {
    arrow: roundArrow,
    theme: "fellow",
    trigger: "click",
    content: "Copied!",
    placement: "bottom",
    onShow(instance) {
      setTimeout(() => {
        instance.hide();
      }, 3000);
    },
  });

  /* *************** *
   *   SIGNUP FORMS  *
   * *************** */

  modal();

  const modalTriggers = document.querySelectorAll("[fellow-modal-trigger]");
  if (modalTriggers.length > 0) {
    modalTriggers.forEach(el => {
      el.addEventListener("click", () => {
        try {
          window.analytics.track("HubspotForm Opened");
        } catch (err) {
          console.error("Error tracking analytics event:", err);
        }
      });
    });
  }

  const signupForms = document.querySelectorAll<HTMLFormElement>("[fellow-signup-form]");
  const signupButtons = document.querySelectorAll<HTMLAnchorElement>(
    [
      "[fellow-signup-button]",
      "[href^='https://app.fellow.co/auth/signup']",
      "[href^='https://app.fellow.co/auth/setup']",
      "[href^='https://fellow.app/auth/signup']",
      "[href^='https://fellow.app/auth/setup']",
    ].join(", "),
  );
  const requestADemoButtons = document.querySelectorAll<HTMLAnchorElement>(
    ["[fellow-request-a-demo-button]", "[href^='https://fellow.app/pricing/request-a-demo']"].join(", "),
  );

  /* *************** *
   *      GFORMS     *
   * *************** */
  setTimeout(() => {
    const gform = document.querySelector("form[id^='gform']");
    if (gform) {
      const splittedId = gform.id.split("_");
      if (splittedId.length === 2) {
        const formId = splittedId[1];
        gform.addEventListener("submit", function () {
          window.analytics.track("HubspotForm Submitted", { formId });
        });
      }
    }
    if (localStorage.getItem("ajs_anonymous_id")) {
      console.log("Segment ID found", localStorage.getItem("ajs_anonymous_id"));
      const element_holder = document.querySelector(".ajs_anonymous_id");
      let element;
      if (element_holder instanceof HTMLElement) {
        element = element_holder.querySelector("input") as HTMLInputElement;
      }
      if (element) {
        const ajs_anonymous_id = localStorage.getItem("ajs_anonymous_id");
        if (ajs_anonymous_id) {
          element.value = ajs_anonymous_id.replace(/["']/g, "");
        }
      }
    } else {
      console.log("Segment ID not found in local storage");
    }
  }, 5000);

  function trackAction(intent: string, el: HTMLFormElement | HTMLAnchorElement) {
    let noteTemplateId = null;
    let ctaId = null;
    if (el) {
      if (el.href) {
        const url = new URL(el.href);
        noteTemplateId = url.searchParams.get("tid");
      }
      if (el.dataset) {
        ctaId = el.dataset.tracking;
      }
      if (intent === "Signup Intent") {
        const linkedinPixelConversion = document.createElement("img");
        linkedinPixelConversion.width = 1;
        linkedinPixelConversion.height = 1;
        linkedinPixelConversion.src = "https://px.ads.linkedin.com/collect/?pid=2777122&conversionId=3589818&fmt=gif";
        document.body.appendChild(linkedinPixelConversion);
      }
    }
    try {
      window.analytics.track(intent, { noteTemplateId, ctaId });
      window.fbq?.("track", "AddToCart");
      capterraTracking();
    } catch (err) {
      console.error("Error tracking analytics event:", err);
    }
  }

  signupForms.forEach(el => {
    el.addEventListener("submit", () => trackAction("Signup Intent", el));
  });

  signupButtons.forEach(el => {
    el.addEventListener("click", () => trackAction("Signup Intent", el));

    const link = new URL(el.href);
    el.href = link.toString();
  });

  requestADemoButtons.forEach(el => {
    el.addEventListener("click", () => trackAction("Request Demo Intent", el));
  });

  /* ********************** *
   * EXTERNAL LINK TRACKING *
   * ********************** */

  function externalLinkHandler(e: MouseEvent) {
    const { target } = e;
    if (!(target instanceof HTMLAnchorElement)) return;
    if ("ga" in window && !e.defaultPrevented && target.hostname && target.hostname != window.location.hostname) {
      window.ga("send", "event", {
        eventAction: "click",
        eventCategory: "outbound",
        eventLabel: target.href,
        transportType: "beacon",
      });
    }
  }
  document.addEventListener("click", externalLinkHandler);

  /**
   * Track Hubspot form submissions in Segment
   */
  if (window.jQuery) {
    const eBookFormId = 2;
    // This is deprecated and hence why we have the window.analytics.track commented inside of it
    // We are using this to test linkedin's pixel but we should migrate to the GFORM
    // Tracking above
    window.jQuery(document).on("gform_confirmation_loaded", (_event: Event, formId: number) => {
      if (formId == eBookFormId) {
        const linkedinPixelConversion = document.createElement("img");
        linkedinPixelConversion.width = 1;
        linkedinPixelConversion.height = 1;
        linkedinPixelConversion.src = "https://px.ads.linkedin.com/collect/?pid=2777122&conversionId=3589834&fmt=gif";
        document.body.appendChild(linkedinPixelConversion);
      }
      // try {
      //   // Roughly following Segment's Noun Verbed format
      //   window.analytics.track("HubspotForm Submitted", { formId });
      // } catch (err) {}

      // const email = document.getElementById("fellow_gform_email"),
      //   tools = document.getElementById("fellow_gform_tools"),
      //   source = document.getElementById("fellow_gform_source");
    });
  }

  /* ********************** *
   * PILLAR PAGE NAVIGATION *
   * ********************** */

  const pillarNav = document.querySelector<HTMLElement>(".pillarpage-nav");
  const chapters = document.querySelectorAll<HTMLDivElement>(".fgb-pillarpage-section");

  if (pillarNav && chapters.length && headerSpacer) {
    let currentChapter = 0;

    const skipHandler = (forward: boolean) => (event: MouseEvent) => {
      event.preventDefault();
      const target = chapters[forward ? currentChapter : currentChapter - 2];
      window.scrollTo({
        top: target.offsetTop - headerSpacer.offsetHeight,
        behavior: "smooth",
      });
    };

    const [back, forward] = pillarNav.querySelectorAll<HTMLAnchorElement>(".pillarpage-nav__skip");

    function updatePillarNav() {
      currentChapter = 0;
      const threshold = window.innerHeight * 0.4;

      while (chapters[currentChapter] && chapters[currentChapter].getBoundingClientRect().top < threshold) {
        currentChapter++;
      }

      if (chapters[currentChapter - 2]) {
        const small = chapters[currentChapter - 2].querySelector<HTMLSpanElement>("small");
        if (small) {
          back.innerText = small.innerText;
          back.style.opacity = "1";
        }
      } else {
        back.style.opacity = "0";
      }

      if (chapters[currentChapter]) {
        const small = chapters[currentChapter].querySelector<HTMLSpanElement>("small");
        if (small) {
          forward.innerText = small.innerText;
          forward.style.opacity = "1";
        }
      } else {
        forward.style.opacity = "0";
      }
    }

    updatePillarNav();
    back.addEventListener("click", skipHandler(false));
    forward.addEventListener("click", skipHandler(true));

    const throttledUpdate = throttle(updatePillarNav, 100);
    window.addEventListener("resize", throttledUpdate);
    window.addEventListener("scroll", throttledUpdate);

    // It's set to `display: none` by default – now that this is set up, have it show up
    pillarNav.style.display = "";
  }

  /*************************
   *      AUDIO PLAYER      *
   * ********************** */

  const audioPlayer = document.querySelector<HTMLAudioElement>("#audioPlayer");

  if (audioPlayer) {
    const playButton = document.querySelector<HTMLImageElement>(".audio-player__play-button");
    const currentTimeText = document.querySelector<HTMLParagraphElement>(".audio-player__current-time");
    const progressBar = document.querySelector<HTMLDivElement>("#progressBar");
    const progressFillBar = document.querySelector<HTMLDivElement>("#progressBar .audio-player__bar--filled");
    const progressDragButton = document.querySelector<HTMLDivElement>("#progressBar .audio-player__drag-button");
    const volumeBar = document.querySelector<HTMLDivElement>("#volumeBar");
    const volumeFillBar = document.querySelector<HTMLDivElement>("#volumeBar .audio-player__bar--filled");
    const volumeDragButton = document.querySelector<HTMLDivElement>("#volumeBar .audio-player__drag-button");
    const durationText = document.querySelector<HTMLParagraphElement>(".audio-player__duration");
    const volumeButton = document.querySelector<HTMLImageElement>(".audio-player__audio-volume--icon");
    let draggingProgressBar = false;
    let draggingVolumeBar = false;

    const formatAudioTime = (timeInSeconds: number) => {
      timeInSeconds = Math.ceil(timeInSeconds);
      const hours = Math.floor(timeInSeconds / 3600);
      const minutes = Math.floor((timeInSeconds - hours * 3600) / 60);
      const seconds = timeInSeconds - hours * 3600 - minutes * 60;
      const hoursText = hours >= 10 ? hours : `0${hours}`;
      const minutesText = minutes >= 10 ? minutes : `0${minutes}`;
      const secondsText = seconds >= 10 ? seconds : `0${seconds}`;

      return `${hours != 0 ? `${hoursText}:` : ""}${minutesText}:${secondsText}`;
    };
    const toggleAudioPlay = () => {
      if (audioPlayer.paused) {
        audioPlayer.play();
        playButton?.setAttribute("src", `${window.fellowAssetsPrefix}img/audio-player/pause-button.svg`);
      } else {
        audioPlayer.pause();
        playButton?.setAttribute("src", `${window.fellowAssetsPrefix}img/audio-player/play-button.svg`);
      }
    };
    const audioEnded = () => {
      playButton?.setAttribute("src", `${window.fellowAssetsPrefix}img/audio-player/play-button.svg`);
    };
    const toggleVolumeMute = () => {
      audioPlayer.muted = !audioPlayer.muted;
      if (audioPlayer.muted) {
        volumeButton?.setAttribute("src", `${window.fellowAssetsPrefix}img/audio-player/volume-muted.svg`);
      } else {
        volumeButton?.setAttribute("src", `${window.fellowAssetsPrefix}img/audio-player/volume.svg`);
      }
    };
    const updateProgressBar = () => {
      if (currentTimeText) {
        currentTimeText.innerHTML = formatAudioTime(audioPlayer.currentTime);
      }
      const percent = (audioPlayer.currentTime / audioPlayer.duration) * 100 + "%";
      if (progressFillBar) progressFillBar.style.width = percent;
      if (progressDragButton) {
        progressDragButton.style.left = `calc(${percent} - ${progressDragButton.offsetWidth / 2}px)`;
      }
    };
    const changeCurrentTime = (event: TouchEvent | MouseEvent) => {
      const clientX = "touches" in event ? event.touches[0].clientX : event.clientX;
      if (progressBar) {
        let percent = (clientX - progressBar.getBoundingClientRect().left) / progressBar.offsetWidth;
        percent = Math.min(1, Math.max(0, percent));
        audioPlayer.currentTime = audioPlayer.duration * percent;
        updateProgressBar();
      }
      if (!draggingProgressBar) {
        window.addEventListener("mousemove", changeCurrentTime);
        window.addEventListener("touchmove", changeCurrentTime);
        window.addEventListener("mouseup", stopDrag);
        window.addEventListener("touchend", stopDrag);
        draggingProgressBar = true;
      }
    };
    const updateVolumeBar = () => {
      const percent = audioPlayer.volume * 100 + "%";
      if (volumeFillBar) volumeFillBar.style.width = percent;
      if (volumeDragButton) volumeDragButton.style.left = `calc(${percent} - ${volumeDragButton.offsetWidth / 2}px)`;
    };
    const changeVolume = (event: TouchEvent | MouseEvent) => {
      const clientX = "touches" in event ? event.touches[0].clientX : event.clientX;
      if (volumeBar) {
        let percent = (clientX - volumeBar.getBoundingClientRect().left) / volumeBar.offsetWidth;
        percent = Math.min(1, Math.max(0, percent));
        audioPlayer.volume = percent;
      }
      if (!draggingVolumeBar) {
        window.addEventListener("mousemove", changeVolume);
        window.addEventListener("touchmove", changeVolume);
        window.addEventListener("mouseup", stopDrag);
        window.addEventListener("touchend", stopDrag);
        draggingVolumeBar = false;
      }
    };
    const stopDrag = () => {
      window.removeEventListener("mousemove", changeCurrentTime);
      window.removeEventListener("mousemove", changeVolume);
      window.removeEventListener("touchmove", changeCurrentTime);
      window.removeEventListener("touchmove", changeVolume);
      window.removeEventListener("mouseup", stopDrag);
      window.removeEventListener("touchend", stopDrag);
      draggingProgressBar = false;
      draggingVolumeBar = false;
    };
    const setupAudioPlayer = () => {
      if (durationText) durationText.innerHTML = formatAudioTime(audioPlayer.duration);
      updateVolumeBar();
    };

    if (playButton) {
      playButton.addEventListener("click", toggleAudioPlay);
      audioPlayer.addEventListener("ended", audioEnded);
    }
    if (volumeButton) {
      volumeButton.addEventListener("click", toggleVolumeMute);
    }
    if (progressBar && progressFillBar && progressDragButton) {
      audioPlayer.addEventListener("timeupdate", updateProgressBar);
      progressBar.addEventListener("mousedown", changeCurrentTime);
      progressBar.addEventListener("touchstart", changeCurrentTime);
      progressDragButton.addEventListener("mousedown", changeCurrentTime);
      progressDragButton.addEventListener("touchstart", changeCurrentTime);
    }
    if (volumeBar && volumeFillBar && volumeDragButton) {
      audioPlayer.addEventListener("volumechange", updateVolumeBar);
      volumeBar.addEventListener("mousedown", changeVolume);
      volumeBar.addEventListener("touchstart", changeVolume);
      volumeDragButton.addEventListener("mousedown", changeVolume);
      volumeDragButton.addEventListener("touchstart", changeVolume);
    }
    if (audioPlayer.readyState === 4) {
      setupAudioPlayer();
    } else {
      audioPlayer.addEventListener("loadedmetadata", setupAudioPlayer);
    }
  }

  /* **************************************** *
   *         FILTER AND CATEGORIZATION        *
   * **************************************** */
  const filters = document.querySelectorAll(".filter");
  if (filters && filters.length > 0) {
    filters.forEach(filter => {
      const searchInput = filter.querySelector<HTMLInputElement>("input[type='search']");
      const clearInputButton = filter.querySelector<HTMLSpanElement>(".filter-categorization__clear-icon");
      if (searchInput && clearInputButton) {
        const setSearchIconVisibility = () => {
          if (searchInput.value && searchInput.value.length > 0) {
            clearInputButton.style.display = "inline";
          } else {
            clearInputButton.style.display = "none";
          }
        };
        const clickCloseIcon = () => {
          searchInput.value = "";
          searchInput.focus();
        };
        searchInput.addEventListener("focus", () => {
          setSearchIconVisibility();
          searchInput.addEventListener("input", setSearchIconVisibility);
        });
        clearInputButton.addEventListener("touchstart", clickCloseIcon);
        clearInputButton.addEventListener("mousedown", clickCloseIcon);
      }
      const filterDropdownButton = filter.querySelector<HTMLButtonElement>(".filter-categorization__dropdown-button");
      const filterDropdownContent = filter.querySelector<HTMLDivElement>(".filter-categorization__dropdown-content");
      if (filterDropdownButton && filterDropdownContent) {
        //  Using listener on window and not on button to detect clicks outside of the button to close dropdown
        //  We could toggle this listener on/off only when the dropdown is open but I think that this is better
        window.addEventListener("click", event => {
          if (event.target === filterDropdownButton) {
            filterDropdownButton.classList.toggle("active");
            filterDropdownContent.classList.toggle("show");
            if (filterDropdownButton === filterDropdownContent.ownerDocument.activeElement) {
              filterDropdownButton.blur();
            }
          } else {
            filterDropdownButton.classList.remove("active");
            filterDropdownContent.classList.remove("show");
          }
        });
      }
    });
    const stickyFilterSentinel = document.querySelector<HTMLDivElement>(".sticky-filter__sentinel");
    const stickyFilterSentinelEnd = document.querySelector<HTMLDivElement>(".sticky-filter__sentinel-end");
    const stickyFilter = document.querySelector<HTMLDivElement>(".sticky-filter");

    if (siteHeader && stickyFilterSentinel && stickyFilterSentinelEnd && stickyFilter) {
      const updateStickyFilterPosition = () => {
        stickyFilter.style.top = `${siteHeader.offsetHeight}px`;
      };
      const updateStickyStyle = () => {
        const bounding = stickyFilterSentinel.getBoundingClientRect();
        const endBounding = stickyFilterSentinelEnd.getBoundingClientRect();
        const startSentinelVisible =
          bounding.top >= 0 &&
          bounding.left >= 0 &&
          bounding.right <= window.innerWidth &&
          bounding.bottom <= window.innerHeight;
        if (startSentinelVisible || endBounding.top <= siteHeader.offsetHeight + stickyFilter.offsetHeight) {
          stickyFilter.classList.remove("stuck");
        } else {
          stickyFilter.classList.add("stuck");
        }
      };
      const throttledUpdateStickyPosition = throttle(updateStickyFilterPosition, 100);
      const throttledUpdateStickyStyle = throttle(updateStickyStyle, 100);
      window.addEventListener("resize", throttledUpdateStickyPosition);
      window.addEventListener("scroll", throttledUpdateStickyStyle);
      throttledUpdateStickyPosition();
    }
  }
  const desktopFilter = document.querySelector<HTMLElement>(".templates-page aside");
  if (desktopFilter) {
    const marginTop = 32;
    const updateStickyFilterPosition = () => {
      if (siteHeader) desktopFilter.style.top = `${siteHeader.offsetHeight + marginTop}px`;
    };
    const throttledUpdateStickyPosition = throttle(updateStickyFilterPosition, 100);
    window.addEventListener("resize", throttledUpdateStickyPosition);
    throttledUpdateStickyPosition();
  }

  /* ************************ *
   *  TEMPLATE/VIDEO SLIDERS  *
   * ************************ */
  const sliders = document.querySelectorAll<HTMLUListElement>(
    ".template-page__items-cards, .videos-block > ul, .testimonial-cards-block > ul",
  );
  if (sliders && sliders.length > 0) {
    sliders.forEach(slider => {
      let isDown = false;
      let startX: number;
      let scrollLeft: number;
      slider.addEventListener("mousedown", e => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
      });
      slider.addEventListener("mouseleave", () => {
        isDown = false;
      });
      slider.addEventListener("mouseup", () => {
        isDown = false;
      });
      slider.addEventListener("mousemove", e => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = x - startX;
        slider.scrollLeft = scrollLeft - walk;
      });
    });
  }

  /* ********************* *
   *      PRICING PAGE     *
   * ********************* */
  // TK: remove toggle logic
  const tierBlocks = document.querySelector<HTMLElement>("#tier-blocks");
  const tierBlocksScrollLeft = document.querySelector<HTMLButtonElement>("#tier-blocks__scroll-arrow--left");
  const tierBlocksScrollRight = document.querySelector<HTMLButtonElement>("#tier-blocks__scroll-arrow--right");
  if (tierBlocks && tierBlocksScrollLeft && tierBlocksScrollRight) {
    function scrollTierBlocks(dir: "left" | "right") {
      if (!tierBlocks) throw new Error("tierBlocks not found");
      const scrollAmount =
        (tierBlocks.children[1] as HTMLElement).offsetLeft - (tierBlocks.children[0] as HTMLElement).offsetLeft;
      if (!scrollAmount) throw new Error("no child element");
      tierBlocks.scrollBy({
        left: dir === "left" ? -scrollAmount : scrollAmount,
        behavior: "smooth",
      });
    }
    tierBlocksScrollLeft.addEventListener("click", () => scrollTierBlocks("left"));
    tierBlocksScrollRight.addEventListener("click", () => scrollTierBlocks("right"));

    function setArrowsEnabled() {
      if (!tierBlocks || !tierBlocksScrollLeft || !tierBlocksScrollRight) return;
      tierBlocksScrollLeft.disabled = tierBlocks.scrollLeft === 0;
      tierBlocksScrollRight.disabled = tierBlocks.scrollLeft + tierBlocks.clientWidth >= tierBlocks.scrollWidth - 1;
    }
    const setArrowsEnabledThrottled = throttle(setArrowsEnabled, 100);
    tierBlocks.addEventListener("scroll", setArrowsEnabledThrottled);
    setArrowsEnabledThrottled();

    function setArrowsVisible() {
      if (!tierBlocks || !tierBlocksScrollLeft || !tierBlocksScrollRight) return;
      tierBlocksScrollLeft.style.display = tierBlocks.scrollWidth <= tierBlocks.clientWidth ? "none" : "";
      tierBlocksScrollRight.style.display = tierBlocks.scrollWidth <= tierBlocks.clientWidth ? "none" : "";
      setArrowsEnabledThrottled();
    }
    const setArrowsVisibleThrottled = throttle(setArrowsVisible, 100);
    window.addEventListener("resize", setArrowsVisibleThrottled);
    setArrowsVisibleThrottled();
  }

  const pricingFeaturesHeader = document.querySelector<HTMLDivElement>("#pricing-features__sticky-header");
  if (pricingFeaturesHeader) {
    const pricingFeaturesTitle = pricingFeaturesHeader.querySelector("h2");
    const originalPricingHeaderText = pricingFeaturesTitle?.textContent ?? "Feature list";
    function updatePricingFeaturesHeader() {
      if (!pricingFeaturesHeader) return;
      const headerRect = pricingFeaturesHeader.getBoundingClientRect();
      pricingFeaturesHeader?.classList.toggle(
        "pricing-features__sticky-header--stuck",
        headerRect.top < (siteHeader?.offsetHeight ?? 72) + 1,
      );

      if (pricingFeaturesTitle) {
        const featureSections = document.querySelectorAll(".pricing-features__section-name");
        let currentSectionTitle: string | undefined = undefined;
        for (let i = featureSections.length - 1; i >= 0; i--) {
          if (featureSections[i].getBoundingClientRect().top < headerRect.top + headerRect.height) {
            currentSectionTitle = featureSections[i].textContent?.trim();
            break;
          }
        }
        pricingFeaturesTitle.textContent = currentSectionTitle || originalPricingHeaderText;
      }
    }
    const throttledUpdatePricingFeaturesHeaderStyle = throttle(updatePricingFeaturesHeader, 100);
    window.addEventListener("scroll", throttledUpdatePricingFeaturesHeaderStyle);
  }

  document.querySelectorAll<HTMLButtonElement>(".contactUs__btn, .addon__btn").forEach(button => {
    button.addEventListener("click", () => {
      if (window.HubSpotConversations?.widget && window.HubSpotConversations.widget.status().loaded) {
        window.HubSpotConversations.widget.open();
      } else {
        window.location.pathname = "/contact";
      }
    });
  });

  const billingCycleSelect = document.querySelector<HTMLSelectElement>("#cycle-select");
  const proPlanPrice = document.querySelector<HTMLDivElement>("#price");
  if (billingCycleSelect && proPlanPrice) {
    billingCycleSelect.addEventListener("change", () => {
      const selected = billingCycleSelect.value;
      proPlanPrice.innerHTML = selected === "annually" ? "$7" : "$11";
    });
  }

  /* ******************************** *
   *      MEETING COST CALCULATOR     *
   * ******************************** */
  const PARTICIPANT_INTERVAL = 1;
  const SALARY_INTERVAL = 5000;
  const DURATION_INTERVAL = 0.5;
  const MEETING_INTERVAL = 1;

  const meetingCalculator = document.querySelector<HTMLDivElement>("#meeting-calculator");
  const formatter = new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: "USD",
    currencyDisplay: "narrowSymbol",
    notation: "compact",
  });
  if (meetingCalculator) {
    // ADDITION AND SUBTRACTION FOR PARTICIPANT INPUT
    const participantsInput = document.querySelector<HTMLInputElement>("#participants__input");
    const salaryInput = document.querySelector<HTMLInputElement>("#salary__input");
    const durationInput = document.querySelector<HTMLInputElement>("#duration__input");
    const meetingInput = document.querySelector<HTMLInputElement>("#meetings__input");

    participantsInput?.addEventListener("change", e => {
      const target = e.target as HTMLInputElement;
      participantsInput.value = Math.max(parseInt(target.value), PARTICIPANT_INTERVAL).toFixed(0);
      recalculate();
    });

    salaryInput?.addEventListener("change", e => {
      const target = e.target as HTMLInputElement;
      salaryInput.value = Math.max(parseInt(target.value), SALARY_INTERVAL).toFixed(0);
      recalculate();
    });

    durationInput?.addEventListener("change", e => {
      const target = e.target as HTMLInputElement;
      durationInput.value = Math.max(parseFloat(target.value), DURATION_INTERVAL).toString();
      recalculate();
    });

    meetingInput?.addEventListener("change", e => {
      const target = e.target as HTMLInputElement;
      meetingInput.value = Math.max(parseFloat(target.value), DURATION_INTERVAL).toString();
      recalculate();
    });

    function getDynamicLink() {
      return `https://fellow.app/tools/meeting-cost-calculator/?participants=${participantsInput?.value}&salary=${salaryInput?.value}&duration=${durationInput?.value}&count=${meetingInput?.value}`;
    }

    function recalculate() {
      // RESULT CARDS - Grabbing input values for use in equations
      if (!participantsInput || !salaryInput || !durationInput || !meetingInput) return;

      const participantsNum = parseInt(participantsInput.value);
      const salaryNum = parseInt(salaryInput.value);
      const durationNum = parseFloat(durationInput.value);
      const meetingNum = parseInt(meetingInput.value);

      // Calculating result cards
      const costPerMeeting = Math.round((salaryNum / 52 / 40) * durationNum * participantsNum);
      const costPerWeek = costPerMeeting * meetingNum;
      const results = costPerWeek * 52;
      const employeeHoursAnnually = participantsNum * meetingNum * durationNum * 52;
      const meetingsPerYear = meetingNum * 52;

      // Grabbing inner HTML
      const costResults = document.querySelector<HTMLDivElement>("#costResults");
      const meetingCost = document.querySelector<HTMLDivElement>("#meetingCost");
      const weeklyCost = document.querySelector<HTMLDivElement>("#weeklyCost");
      const employeeHours = document.querySelector<HTMLDivElement>("#employeeHours");
      const yearlyMeetings = document.querySelector<HTMLDivElement>("#yearlyMeetings");
      const resultDescription = document.querySelector<HTMLDivElement>("#resultDescription");

      // Metrics with Fellow Pro
      const calculatedSavings = document.querySelector<HTMLDivElement>("#calculatedSavings");
      const hoursBack = document.querySelector<HTMLDivElement>("#hoursBack");
      const calculatedWeeks = document.querySelector<HTMLDivElement>("#calculatedWeeks");
      const calculatedLessMeetings = document.querySelector<HTMLDivElement>("#calculatedLessMeetings");

      // Output inner HTML
      if (costResults) costResults.innerHTML = formatter.format(results);
      if (meetingCost) meetingCost.innerHTML = formatter.format(costPerMeeting);
      if (employeeHours) employeeHours.innerHTML = employeeHoursAnnually.toLocaleString(navigator.language);
      if (weeklyCost) weeklyCost.innerHTML = formatter.format(costPerWeek);
      if (yearlyMeetings) yearlyMeetings.innerHTML = meetingsPerYear.toLocaleString(navigator.language);
      if (resultDescription)
        resultDescription.innerHTML = `For ${participantsNum} people making ${formatter.format(
          salaryNum,
        )} annually to meet ${meetingNum}x per week for ${durationNum} hour${durationNum === 1 ? "" : "s"}`;

      // Calculating metrics
      const weeksWorth = document.querySelector<HTMLDivElement>("#weeksWorth");
      const savings = results * 0.16;
      if (calculatedSavings) calculatedSavings.innerHTML = formatter.format(savings);
      if (hoursBack) hoursBack.innerHTML = Math.round(employeeHoursAnnually * 0.16).toFixed(0);
      const weeksOfFellow = savings / 12 / participantsNum / 6;
      if (calculatedWeeks) calculatedWeeks.innerHTML = weeksOfFellow.toFixed(1);
      if (calculatedLessMeetings) calculatedLessMeetings.innerHTML = (meetingsPerYear * 0.16).toFixed(1);

      const yearlyCostOfFellow = participantsNum * 12 * 6;
      const savingsPerWeek = savings / 52;
      const weeksTillBreakeven = Math.ceil(yearlyCostOfFellow / savingsPerWeek);
      if (weeksTillBreakeven > 1) {
        if (weeksWorth) weeksWorth.innerHTML = `${weeksTillBreakeven.toFixed(0)} weeks`;
      } else {
        if (weeksWorth) weeksWorth.innerHTML = `${weeksTillBreakeven.toFixed(0)} week`;
      }

      document
        .getElementById("emailResults")
        ?.setAttribute(
          "href",
          `mailto:?subject=Take a look at this &body=Hey! %0D%0A%0D%0A Check out this new page I just found: ${encodeURIComponent(
            getDynamicLink(),
          )} - let me know what you think %0D%0A%0D%0A Cheers!`,
        );
    }
    // QUERY PARAMS FOR SEARCH
    const params = new URLSearchParams(window.location.search);
    if (participantsInput) participantsInput.value = params.get("participants") ?? "5";
    if (salaryInput) salaryInput.value = params.get("salary") ?? "65000";
    if (durationInput) durationInput.value = params.get("duration") ?? "1";
    if (meetingInput) meetingInput.value = params.get("count") ?? "2";

    // Copy results link - Calculator section
    const shareResultsBtn = document.querySelector<HTMLButtonElement>("#shareResultsBtn");
    shareResultsBtn?.addEventListener("click", () => {
      navigator.clipboard?.writeText(getDynamicLink());
    });

    // Copy general page link - share section
    const textToCopy = document.getElementById("meeting-cost-url")?.innerText ?? "";
    const copyToClipboardButton = document.querySelector<HTMLButtonElement>("#copyCalculatorUrl");
    copyToClipboardButton?.addEventListener("click", () => {
      navigator.clipboard.writeText(textToCopy);
    });

    recalculate();

    document.querySelector<HTMLButtonElement>("#participantAdd__btn")?.addEventListener("click", () => {
      if (!participantsInput) return;
      const participantsNum = parseInt(participantsInput.value);
      participantsInput.value = (participantsNum + PARTICIPANT_INTERVAL).toFixed(0);
      recalculate();
    });
    document.querySelector<HTMLButtonElement>("#participantSub__btn")?.addEventListener("click", () => {
      if (!participantsInput) return;
      const participantsNum = parseInt(participantsInput.value);
      participantsInput.value = Math.max(PARTICIPANT_INTERVAL, participantsNum - PARTICIPANT_INTERVAL).toFixed(0);
      recalculate();
    });

    // ADDITION AND SUBTRACTION FOR AVERAGE ANNUAL SALARY INPUT
    document.querySelector<HTMLButtonElement>("#salaryAdd__btn")?.addEventListener("click", () => {
      if (!salaryInput) return;
      const salaryNum = parseInt(salaryInput.value);
      salaryInput.value = (salaryNum + SALARY_INTERVAL).toFixed(0);
      recalculate();
    });
    document.querySelector<HTMLButtonElement>("#salarySub__btn")?.addEventListener("click", () => {
      if (!salaryInput) return;
      const salaryNum = parseInt(salaryInput.value);
      salaryInput.value = Math.max(SALARY_INTERVAL, salaryNum - SALARY_INTERVAL).toFixed(0);
      recalculate();
    });

    // ADDITION AND SUBTRACTION FOR DURATION INPUT
    document.querySelector<HTMLButtonElement>("#durationAdd__btn")?.addEventListener("click", () => {
      if (!durationInput) return;
      const durationNum = parseFloat(durationInput.value);
      durationInput.value = (durationNum + DURATION_INTERVAL).toString();
      recalculate();
    });
    document.querySelector<HTMLButtonElement>("#durationSub__btn")?.addEventListener("click", () => {
      if (!durationInput) return;
      const durationNum = parseFloat(durationInput.value);
      durationInput.value = Math.max(DURATION_INTERVAL, durationNum - DURATION_INTERVAL).toString();
      recalculate();
    });

    // ADDITION AND SUBTRACTION FOR MEETINGS PER WEEK
    document.querySelector<HTMLButtonElement>("#meetingsAdd__btn")?.addEventListener("click", () => {
      if (!meetingInput) return;
      const meetingNum = parseInt(meetingInput.value);
      meetingInput.value = (meetingNum + 1).toString();
      recalculate();
    });
    document.querySelector<HTMLButtonElement>("#meetingsSub__btn")?.addEventListener("click", () => {
      if (!meetingInput) return;
      const meetingNum = parseInt(meetingInput.value);
      meetingInput.value = Math.max(MEETING_INTERVAL, meetingNum - MEETING_INTERVAL).toString();
      recalculate();
    });

    const isMobile = window.matchMedia("(max-width: 767px)").matches;
    document.querySelector<HTMLButtonElement>("#calculator__btn")?.addEventListener("click", () => {
      recalculate();
      if (!isMobile) return;

      const results = document.querySelector<HTMLDivElement>("#calculator-results");
      results?.scrollIntoView({ behavior: "smooth" });
    });
  }

  /* ************************ *
   *      FEATURE BLOCK       *
   * ************************ */
  const featureBlocks = document.querySelectorAll(".feature-block");
  if (featureBlocks && featureBlocks.length > 0) {
    featureBlocks[0].classList.add("first");
    featureBlocks[featureBlocks.length - 1].classList.add("last");
  }

  /* ************************* *
   *  TEMPLATE CATEGORY BLOCK  *
   * ************************* */
  // Get all containers holding categories
  const templateCatBlocks = document.querySelectorAll(".template-category-block");
  // Following code should only be run if a template categories block exists
  if (templateCatBlocks && templateCatBlocks.length > 0) {
    // Go through each block and collect reference for block's button - create event listener which adds class
    templateCatBlocks.forEach(templateCatBlock => {
      // Get reference for button for this specific block
      const viewAllButton = templateCatBlock.querySelector(".template-category-block__viewAllBtn");
      // Following code is if the button is non-existing
      if (!viewAllButton) return;
      // Add listener which adds class to templateCatBlock
      viewAllButton.addEventListener("click", () => {
        const categories = templateCatBlock.querySelector<HTMLDivElement>(".template-category-block__categories");
        categories?.classList.add("show-all");
        viewAllButton.classList.add("hidden");
      });
    });
  }

  /* ************************ *
   *        SHARE MODAL       *
   * ************************ */
  let shareModalOpen = false;
  let shareButtonForImagePreview: HTMLAnchorElement | null = null;
  const shareContainers = document.querySelectorAll(".share-container");
  if (shareContainers && shareContainers.length > 0) {
    shareContainers.forEach(shareContainer => {
      const shareButton = shareContainer.querySelector<HTMLAnchorElement>(".share-button");
      if (!shareButtonForImagePreview) {
        shareButtonForImagePreview = shareButton;
      }
      const shareModal = shareContainer.querySelector<HTMLDivElement>(".share-modal");
      const shareModalClose = shareContainer.querySelector<HTMLSpanElement>(".share-modal__close");
      const shareLink = shareContainer.querySelector<HTMLInputElement>(".share-copy-link input");
      const shareCopyLink = shareContainer.querySelector<HTMLAnchorElement>(".share-copy-link a");
      const openModal = (e: MouseEvent) => {
        if (shareModal) shareModal.style.display = "block";
        shareModalOpen = true;
        document.addEventListener("click", checkClickOutsideModal);
        if (imagePreviewOpen && closeImagePreviewModal) {
          closeImagePreviewModal();
          shareModal?.scrollIntoView({ behavior: "smooth", block: "center" });
        }
        e.stopPropagation();
      };
      const closeModal = () => {
        if (shareModal) shareModal.style.display = "none";
        shareModalOpen = false;
        document.removeEventListener("click", checkClickOutsideModal);
      };
      const checkClickOutsideModal = ({ target }: MouseEvent) => {
        if (!(target instanceof HTMLElement)) return;
        if (!target.closest(".share-modal") && shareModalOpen) {
          closeModal();
        }
      };
      const copyLink = () => {
        shareLink?.select();
        shareLink?.setSelectionRange(0, 99999); /* For mobile devices */
        document.execCommand("copy");
      };
      shareButton?.addEventListener("click", openModal);
      shareModalClose?.addEventListener("click", closeModal);
      if (shareLink && shareCopyLink) {
        shareCopyLink.addEventListener("click", copyLink);
      }
    });
  }

  /* ************************ *
   *       IMAGE PREVIEW      *
   * ************************ */
  let imagePreviewOpen = false;
  let closeImagePreviewModal: () => void;
  const imagePreview = document.querySelector<HTMLDivElement>(".image-preview-container");
  const imagePreviewModal = document.querySelector<HTMLDivElement>(".image-preview__modal");
  const imagePreviewDimmer = document.querySelector<HTMLDivElement>(".image-preview__modal-dimmer");
  const imagePreviewCloseButton = document.querySelector<HTMLSpanElement>(".image-preview__close");
  if (imagePreview && imagePreviewModal) {
    const imagePreviewShareButton = imagePreviewModal.querySelector<HTMLAnchorElement>(".share-button");
    const checkClickOutsideModal = ({ target }: MouseEvent) => {
      if (!(target instanceof HTMLElement)) return;
      if (!target.closest(".image-preview__modal") && target.closest(".share-modal") && imagePreviewOpen) {
        closeImagePreviewModal();
      }
    };
    const openModal = (e: MouseEvent) => {
      imagePreviewModal.style.display = "block";
      if (imagePreviewDimmer) {
        imagePreviewDimmer.style.display = "block";
      }
      document.body.style.overflowY = "hidden";
      imagePreviewOpen = true;
      document.addEventListener("click", checkClickOutsideModal);
      e.stopPropagation();
    };
    closeImagePreviewModal = () => {
      imagePreviewModal.style.display = "none";
      if (imagePreviewDimmer) {
        imagePreviewDimmer.style.display = "none";
      }
      document.body.style.overflowY = "scroll";
      imagePreviewOpen = false;
      document.removeEventListener("click", checkClickOutsideModal);
    };
    imagePreview.addEventListener("click", openModal);
    if (imagePreviewCloseButton) {
      imagePreviewCloseButton.addEventListener("click", closeImagePreviewModal);
    }
    imagePreviewShareButton?.addEventListener("click", e => {
      if (!shareButtonForImagePreview) {
        return;
      }
      shareButtonForImagePreview.click();
      e.stopPropagation();
    });
  }

  /* ************************ *
   *       VIDEO VIEWER       *
   * ************************ */
  const videoContainers = document.querySelectorAll<HTMLElement>(".videos-block__video-container");
  const videoModal = document.querySelector<HTMLDivElement>(".videos-block--modal");
  if (videoContainers && videoContainers.length > 0 && videoModal) {
    let player: YT.Player;
    let youtubeLoaded = false;
    let videoOpened = false;
    window.onYouTubeIframeAPIReady = () => {
      youtubeLoaded = true;
      if (videoOpened) {
        player = new YT.Player("youtube-iframe", {});
      }
    };
    // Injecting youtube iframe API
    const tag = document.createElement("script");
    tag.src = "https://www.youtube.com/iframe_api";
    const firstScriptTag = document.getElementsByTagName("script")[0];
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag);

    const videoIFrame = videoModal.querySelector<HTMLIFrameElement>("iframe");
    const closeVideoModal = () => {
      if (player && player.stopVideo) {
        player.stopVideo();
      }
      document.body.style.overflowY = "scroll";
      videoModal.style.display = "none";
      videoModal.removeEventListener("click", closeVideoModal);
    };
    videoContainers.forEach(videoContainer => {
      if (!videoContainer.hasAttribute("data-url")) {
        return;
      }
      videoContainer.addEventListener("click", () => {
        if (videoIFrame) videoIFrame.src = videoContainer.getAttribute("data-url") + "?enablejsapi=1";
        if (youtubeLoaded && !player) {
          player = new YT.Player("youtube-iframe", {});
        }
        document.body.style.overflowY = "hidden";
        videoModal.style.display = "flex";
        videoModal.addEventListener("click", closeVideoModal);
        videoOpened = true;
      });
    });
  }

  /* **************************** *
   *  TESTIMONIAL CARDS SWITCHER  *
   * **************************** */
  const testimonialCardsSections = document.querySelectorAll(".testimonial-cards-block");
  if (testimonialCardsSections && testimonialCardsSections.length > 0) {
    testimonialCardsSections.forEach(testimonialCardsSection => {
      const testimonialCardsMobileSwitcher = testimonialCardsSection.querySelector<HTMLDivElement>(
        ".testimonial-cards-block__cards-container--mobile.glide",
      );
      if (testimonialCardsMobileSwitcher) {
        new Glide(testimonialCardsMobileSwitcher, {
          type: "slider",
          perView: 1,
          touchAngle: 30,
          autoplay: 10000,
          hoverpause: false,
          animationDuration: 800,
          animationTimingFunc: "ease",
        }).mount();
      }
    });
  }

  /* **************************** *
   *    TWITTER CARDS SWITCHER    *
   * **************************** */
  const twitterCardsSections = document.querySelectorAll(".twitter-cards-block");
  if (twitterCardsSections && twitterCardsSections.length > 0) {
    twitterCardsSections.forEach(twitterCardsSection => {
      const twitterCardsMobileSwitcher = twitterCardsSection.querySelector<HTMLDivElement>(
        ".twitter-cards-block__cards-container--mobile.glide",
      );
      if (twitterCardsMobileSwitcher) {
        new Glide(twitterCardsMobileSwitcher, {
          type: "slider",
          perView: 1,
          touchAngle: 30,
          autoplay: 10000,
          hoverpause: false,
          animationDuration: 800,
          animationTimingFunc: "ease",
        }).mount();
      }
    });
  }

  /* ******************* *
   *   REFERRER MANAGER  *
   * ******************* */
  const UTM_STORE_KEY = "fellow_referrer";
  const UTM_LAST_TOUCH_STORE_KEY = "fellow_referrer_last_touch";
  const UTM_STORE_TIMESTAMP_KEY = "fellow_referrer_timestamp";
  const UTM_PARAMS_OF_INTEREST = [
    "utm_campaign",
    "utm_medium",
    "utm_source",
    "utm_content",
    "utm_term",
    "gclid",
    "fbclid",
    "msclkid",
    "clickid",
  ];

  const result: { [key: string]: string } = {};
  const params = new URLSearchParams(window.location.search);
  for (const [name, value] of params.entries()) {
    if (UTM_PARAMS_OF_INTEREST.includes(name.toLowerCase())) {
      result[name.toLowerCase()] = value;
    }
  }

  if (Object.keys(result).length > 0) {
    window.localStorage.setItem(UTM_LAST_TOUCH_STORE_KEY, JSON.stringify(result));

    const existingReferrer = localStorage.getItem(UTM_STORE_KEY);
    const existingTimestamp = parseInt(localStorage.getItem(UTM_STORE_TIMESTAMP_KEY) ?? "NaN");
    const timestamp = new Date().getTime();

    let daysUntilExpiry = 30;
    const utmSource = result["utm_source"];
    if (utmSource === "facebook") daysUntilExpiry = 7;
    if (utmSource === "google" || utmSource === "bing") daysUntilExpiry = 90;

    const paramsHaveExpired =
      !isNaN(existingTimestamp) && timestamp - existingTimestamp > 1000 * 60 * 60 * 24 * daysUntilExpiry;
    // No referrer found or timestamp is over 7 days old
    if (existingReferrer === null || isNaN(existingTimestamp) || paramsHaveExpired) {
      window.localStorage.setItem(UTM_STORE_TIMESTAMP_KEY, timestamp.toString());
      window.localStorage.setItem(UTM_STORE_KEY, JSON.stringify(result));
    }
  }

  /* ************************ *
   * TWITTER SELECTION SHARE  *
   * ************************ */
  const twitterSelectionShare = document.querySelector<HTMLAnchorElement>("a.twitter-selection-share");
  if (twitterSelectionShare) {
    function hideTwitterSelectionShare(event: MouseEvent | null) {
      const target = event?.target as HTMLElement | null;
      if (target?.closest("a.twitter-selection-share")) {
        return;
      }
      if (twitterSelectionShare) twitterSelectionShare.style.display = "none";
      window.removeEventListener("click", hideTwitterSelectionShare);
    }
    function showTwitterSelectionShare(event: MouseEvent) {
      if (!twitterSelectionShare || !siteHeader) return;
      const selection = twitterSelectionShare.ownerDocument.getSelection();
      if (selection && selection.toString().length > 0) {
        const textRect = selection.getRangeAt(0).getBoundingClientRect();
        const xPositionOffset = -80;
        const yPositionOffset = -25;
        const xPosition = (textRect.right + textRect.left) / 2 + yPositionOffset + window.scrollX;
        const yPosition =
          (textRect.top > Math.abs(yPositionOffset) + siteHeader.offsetHeight ? textRect.top : textRect.bottom) +
          window.scrollY +
          xPositionOffset;
        twitterSelectionShare.href = `https://twitter.com/intent/tweet?text=${selection.toString()}&url=${
          window.location.href
        }`;
        twitterSelectionShare.style.left = `${xPosition}px`;
        twitterSelectionShare.style.top = `${yPosition}px`;
        twitterSelectionShare.style.display = "flex";
        event.stopPropagation();
        window.addEventListener("click", hideTwitterSelectionShare);
      }
      window.removeEventListener("click", showTwitterSelectionShare);
      return false;
    }
    window.addEventListener("selectstart", () => {
      hideTwitterSelectionShare(null);
      window.addEventListener("click", showTwitterSelectionShare);
    });
  }

  /* ************************ *
   *    SCROLL PROGRESS BAR   *
   * ************************ */
  const scrollProgressBar = document.querySelector<HTMLDivElement>("body > .progress-bar");
  if (scrollProgressBar) {
    const scrollProgressBarFilled = scrollProgressBar.querySelector<HTMLDivElement>(".progress-bar--filled");
    if (scrollProgressBarFilled) {
      function updateProgressBar() {
        if (!scrollProgressBarFilled) return;
        const winScroll = document.body.scrollTop || document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        scrollProgressBarFilled.style.width = `${Math.min(100, scrolled)}%`;
      }
      function updateProgressBarPosition() {
        if (!scrollProgressBar || !siteHeader) return;
        let totalHeight = siteHeader.offsetHeight;
        if (topBanner) {
          totalHeight += topBanner.offsetHeight;
        }
        scrollProgressBar.style.top = `${totalHeight}px`;
      }

      const throttledUpdateProgressBar = throttle(updateProgressBar, 10);
      updateProgressBar();
      window.addEventListener("scroll", throttledUpdateProgressBar);

      const throttledUpdateProgressBarPosition = throttle(updateProgressBarPosition, 100);
      const heavilyThrottleUpdateProgressBarPosition = throttle(updateProgressBarPosition, 100);
      window.addEventListener("resize", throttledUpdateProgressBarPosition);
      window.addEventListener("load", throttledUpdateProgressBarPosition);
      siteHeader
        ?.querySelectorAll("img")
        .forEach(el => el.addEventListener("load", throttledUpdateProgressBarPosition));
      //  This is done to handle the browser url showing when you scroll up
      //  Pushing the whole document down
      window.addEventListener("scroll", heavilyThrottleUpdateProgressBarPosition);
      updateProgressBarPosition();
    }
  }

  if (headerSpacer && siteHeader) {
    // The attribute [data-fellow-header-block] is put on any Gutenberg block
    // whose background should show up behind the header. This moves the header
    // spacer div inside that block if appropriate.
    let firstBlock = document.querySelector("body > main > :first-child");
    //  In case we are ab testing and hiding the first hero block
    if (firstBlock && getComputedStyle(firstBlock, null).display === "none") {
      firstBlock = document.querySelector("body > main > :nth-child(2)");
    }
    if (firstBlock && firstBlock.hasAttribute("data-fellow-header-block")) {
      headerSpacer.remove();
      firstBlock.insertBefore(headerSpacer, firstBlock.childNodes[0]);
    }
  }
  /* ********************** *
   *        TOP BANNER      *
   * ********************** */
  if (topBanner && document.querySelector("body:not(.hide-top-banner)")) {
    const repositionPageContent = () => {
      if (siteHeader) siteHeader.style.top = `${topBanner.offsetHeight - 1}px`;
    };
    const hideBanner = () => {
      topBanner.classList.add("top-banner__hidden");
    };
    const removeBanner = (e: MouseEvent) => {
      e.preventDefault();
      hideBanner();
      if (siteHeader) {
        siteHeader.style.top = "0";
        window.removeEventListener("resize", repositionPageContent);
      }
      topBannerController.closedBanner();
    };
    // if (topBannerController.isTopBannerOnCooldown()) {
    //   hideBanner();
    //   return;
    // }
    const closeBanner = document.querySelector<HTMLDivElement>(".top-banner__close");
    if (siteHeader) {
      repositionPageContent();
      window.addEventListener("resize", repositionPageContent);
    }
    closeBanner?.addEventListener("click", removeBanner);
  }

  /* ********************** *
   *        LINKEDIN        *
   * ********************** */
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
  window._linkedin_data_partner_ids.push(window._linkedin_data_partner_id);

  /* ********************** *
   *  LAUNCH FELLOW BUTTON  *
   * ********************** */
  const fellowAccountsValue = Cookies.get("fellow_accounts");
  if (fellowAccountsValue) {
    const fellowAccounts: FellowAccount[] = JSON.parse(fellowAccountsValue);
    if (Object.keys(fellowAccounts).length > 0) {
      function buildFellowAccount(account: FellowAccount) {
        const anchor = document.createElement("a");
        anchor.href = account.uri;
        anchor.className = "fellow-workspaces__workspace";
        anchor.innerHTML = `
          <div class="fellow-workspaces__inner">
            <div class="fellow-workspaces__image">
              <img src="${escape(account.logo)}" alt="${escape(account.name)}" />
            </div>
            <div class="fellow-workspaces__name">
              ${escape(account.name)}
            </div>
          </div>
          <div class="fellow-workspaces__arrow"></div>
        `;
        return anchor;
      }

      const launchFellowButton = document.querySelector<HTMLButtonElement>(".launch-fellow-button");
      const fellowWorkspaceContainer = document.querySelector<HTMLDivElement>(".fellow-workspaces__container");
      const tryForFreeButton = document.querySelector<HTMLButtonElement>(".try-for-free-button");

      if (launchFellowButton) launchFellowButton.style.display = "inline-block";
      if (tryForFreeButton) tryForFreeButton.style.display = "none";

      launchFellowButton?.addEventListener("click", () => {
        launchFellowButton.classList.toggle("open");
        fellowWorkspaceContainer?.classList.toggle("open");
        siteHeader?.classList.toggle("site-header--expanded-no-nav");
      });

      Object.values(fellowAccounts)
        .map(buildFellowAccount)
        .forEach(html => {
          fellowWorkspaceContainer?.prepend(html);
        });
    }
  }

  /* ***************** *
   *  CASE STUDY MENU  *
   * ***************** */
  const caseStudyMenus = document.querySelectorAll<HTMLDivElement>(".saved-block .case-study-block__menu");
  if (caseStudyMenus && caseStudyMenus.length > 0) {
    // A bunch of stuff specific to the last case study block
    const lastCaseStudyMenu = caseStudyMenus[caseStudyMenus.length - 1];
    lastCaseStudyMenu.classList.add("fadeout-for-scroll", "last-menu");
    const lastCaseStudyMenuItems = [
      ...lastCaseStudyMenu.querySelectorAll<HTMLButtonElement>(".case-study-block__menu-item"),
    ].reverse();
    lastCaseStudyMenuItems?.forEach(item => item.classList.remove("case-study-block__menu-item--active"));
    lastCaseStudyMenuItems[0].classList.toggle("case-study-block__menu-item--active", true);

    const firstCaseStudyMenu = caseStudyMenus[0];
    const firstCaseStudyBlockMenuItems = [
      ...firstCaseStudyMenu.querySelectorAll<HTMLButtonElement>(".case-study-block__menu-item"),
    ].reverse();
    const caseStudyBlocks = [...document.querySelectorAll<HTMLDivElement>(".case-study-block.saved-block")].reverse();

    const firstCaseStudyBlock = document.querySelector<HTMLDivElement>(".case-study-block.saved-block:first-child");

    document.addEventListener("scroll", function () {
      const hasScrolled = window.scrollY > 0;
      firstCaseStudyMenu?.classList.toggle("background-for-scroll", hasScrolled);

      const hasScrolledToFirstCaseStudyBlock =
        !!firstCaseStudyBlock && window.scrollY > firstCaseStudyBlock.offsetTop - 74;
      if (!hasScrolledToFirstCaseStudyBlock) {
        firstCaseStudyMenu.style.marginTop = `-${window.scrollY}px`;
      }

      for (const [index, block] of caseStudyBlocks.entries()) {
        if (firstCaseStudyBlock && block.offsetTop < window.scrollY + 467 - (firstCaseStudyBlock.offsetTop - 103)) {
          firstCaseStudyBlockMenuItems?.forEach(item => item.classList.remove("case-study-block__menu-item--active"));
          firstCaseStudyBlockMenuItems?.[index].classList.toggle("case-study-block__menu-item--active", true);

          firstCaseStudyMenu.classList.toggle("fadeout-for-scroll", index === 0);
          lastCaseStudyMenu.classList.toggle("fadeout-for-scroll", index > 0);
          return;
        }
      }
    });

    function listenToMenuItems(item: HTMLButtonElement, index: number) {
      item.addEventListener("click", () => {
        const block = caseStudyBlocks[index];
        if (block) {
          window.scrollTo({
            top: block.offsetTop - 466,
            behavior: "smooth",
          });
        }
      });
    }

    firstCaseStudyBlockMenuItems.forEach(listenToMenuItems);
    lastCaseStudyMenuItems.forEach(listenToMenuItems);
  }

  const navbar = document.querySelector(".custom-nav-bar");
  if (navbar) {
    window.addEventListener("scroll", function () {
      const scrollPercentage = window.scrollY / document.documentElement.scrollHeight;
      if (scrollPercentage > 0.01) {
        navbar.classList.add("has-white-background-color", "scrolled");
      } else {
        navbar.classList.remove("has-white-background-color", "scrolled");
      }
    });
  }
});

type FellowAccount = {
  id: number | string;
  logo: string;
  name: string;
  uri: string;
};

// @ts-ignore
window._growthbook = growthbook; // without this growthbook is not able to detect the presense of the instance
